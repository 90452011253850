import { TArtwork } from "../utils/types";

export const artworks: TArtwork[] = [
  {
    _id: "mermaid",
    name: "mermaid",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/mermaid.jpg"),
    path: "/gallery/2017/catasian/mermaid",
  },
  {
    _id: "ballet",
    name: "ballet",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/ballet.jpg"),
    path: "/gallery/2017/catasian/ballet",
  },
  {
    _id: "birdie",
    name: "birdie",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/birdie.jpg"),
    path: "/gallery/2017/catasian/birdie",
  },
  {
    _id: "deer",
    name: "deer",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/deer.jpg"),
    path: "/gallery/2017/catasian/deer",
  },
  {
    _id: "dragon",
    name: "dragon",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/dragon.jpg"),
    path: "/gallery/2017/catasian/dragon",
  },
  {
    _id: "eight",
    name: "eight",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/eight.jpg"),
    path: "/gallery/2017/catasian/eight",
  },
  {
    _id: "octopus",
    name: "octopus",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/octopus.jpg"),
    path: "/gallery/2017/catasian/octopus",
  },
  {
    _id: "party",
    name: "party",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/party.jpg"),
    path: "/gallery/2017/catasian/party",
  },
  {
    _id: "snakie",
    name: "snakie",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/snakie.jpg"),
    path: "/gallery/2017/catasian/snakie",
  },
  {
    _id: "yoga",
    name: "yoga",
    year: "2017",
    project: "catasian",
    url: require("../assets/artworks/2017/catasian/yoga.jpg"),
    path: "/gallery/2017/catasian/yoga",
  },
  {
    _id: "catgirl1",
    name: "catgirl 1",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl1.jpg"),
    path: "/gallery/2017/catgirls/catgirl1",
  },
  {
    _id: "catgirl10",
    name: "catgirl 10",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl10.jpg"),
    path: "/gallery/2017/catgirls/catgirl10",
  },
  {
    _id: "catgirl11",
    name: "catgirl 11",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl11.jpg"),
    path: "/gallery/2017/catgirls/catgirl11",
  },
  {
    _id: "catgirl12",
    name: "catgirl 12",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl12.jpg"),
    path: "/gallery/2017/catgirls/catgirl12",
  },
  {
    _id: "catgirl2",
    name: "catgirl 2",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl2.jpg"),
    path: "/gallery/2017/catgirls/catgirl2",
  },
  {
    _id: "catgirl3",
    name: "catgirl 3",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl3.jpg"),
    path: "/gallery/2017/catgirls/catgirl3",
  },
  {
    _id: "catgirl4",
    name: "catgirl 4",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl4.jpg"),
    path: "/gallery/2017/catgirls/catgirl4",
  },
  {
    _id: "catgirl5",
    name: "catgirl 5",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl5.jpg"),
    path: "/gallery/2017/catgirls/catgirl5",
  },
  {
    _id: "catgirl6",
    name: "catgirl 6",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl6.jpg"),
    path: "/gallery/2017/catgirls/catgirl6",
  },
  {
    _id: "catgirl7",
    name: "catgirl 7",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl7.jpg"),
    path: "/gallery/2017/catgirls/catgirl7",
  },
  {
    _id: "catgirl8",
    name: "catgirl 8",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl8.jpg"),
    path: "/gallery/2017/catgirls/catgirl8",
  },
  {
    _id: "catgirl9",
    name: "catgirl 9",
    year: "2017",
    project: "catgirls",
    url: require("../assets/artworks/2017/catgirls/catgirl9.jpg"),
    path: "/gallery/2017/catgirls/catgirl9",
  },
  {
    _id: "anya",
    name: "anya",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/anya.jpg"),
    path: "/gallery/2017/animalasian/anya",
  },
  {
    _id: "chiquita",
    name: "chiquita",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/chiquita.jpg"),
    path: "/gallery/2017/animalasian/chiquita",
  },
  {
    _id: "eva",
    name: "eva",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/eva.jpg"),
    path: "/gallery/2017/animalasian/eva",
  },
  {
    _id: "katya",
    name: "katya",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/katya.jpg"),
    path: "/gallery/2017/animalasian/katya",
  },
  {
    _id: "oliver",
    name: "oliver",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/oliver.jpg"),
    path: "/gallery/2017/animalasian/oliver",
  },
  {
    _id: "rosa",
    name: "rosa",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/rosa.jpg"),
    path: "/gallery/2017/animalasian/rosa",
  },
  {
    _id: "rudy",
    name: "rudy",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/rudy.jpg"),
    path: "/gallery/2017/animalasian/rudy",
  },
  {
    _id: "sara",
    name: "sara",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/sara.jpg"),
    path: "/gallery/2017/animalasian/sara",
  },
  {
    _id: "sean",
    name: "sean",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/sean.jpg"),
    path: "/gallery/2017/animalasian/sean",
  },
  {
    _id: "sveta",
    name: "sveta",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/sveta.jpg"),
    path: "/gallery/2017/animalasian/sveta",
  },
  {
    _id: "taisia",
    name: "taisia",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/taisia.jpg"),
    path: "/gallery/2017/animalasian/taisia",
  },
  {
    _id: "tom",
    name: "tom",
    year: "2017",
    project: "animalasian",
    url: require("../assets/artworks/2017/animalasian/tom.jpg"),
    path: "/gallery/2017/animalasian/tom",
  },
  {
    _id: "city-faces-more",
    name: "city faces more",
    year: "2018",
    project: "best-2018",
    url: require("../assets/artworks/2018/city-faces-more.jpg"),
    path: "/gallery/2018/best-2018/city-faces-more",
  },
  {
    _id: "city-faces",
    name: "city faces",
    year: "2018",
    project: "best-2018",
    url: require("../assets/artworks/2018/city-faces.jpg"),
    path: "/gallery/2018/best-2018/city-faces",
  },
  {
    _id: "faces",
    name: "faces",
    year: "2018",
    project: "best-2018",
    url: require("../assets/artworks/2018/faces.jpg"),
    path: "/gallery/2018/best-2018/faces",
  },
  {
    _id: "fishflying",
    name: "fishflying",
    year: "2018",
    project: "best-2018",
    url: require("../assets/artworks/2018/fishflying.jpg"),
    path: "/gallery/2018/best-2018/fishflying",
  },
  {
    _id: "metro-life",
    name: "metro life",
    year: "2018",
    project: "best-2018",
    url: require("../assets/artworks/2018/metro-life.jpg"),
    path: "/gallery/2018/best-2018/metro-life",
  },
  {
    _id: "prince-charming",
    name: "prince charming",
    year: "2018",
    project: "best-2018",
    url: require("../assets/artworks/2018/prince-charming.jpg"),
    path: "/gallery/2018/best-2018/prince-charming",
  },
  {
    _id: "street-life",
    name: "street life",
    year: "2018",
    project: "best-2018",
    url: require("../assets/artworks/2018/street-life.jpg"),
    path: "/gallery/2018/best-2018/street-life",
  },
  {
    _id: "three-cats",
    name: "three cats",
    year: "2018",
    project: "best-2018",
    url: require("../assets/artworks/2018/three-cats.jpg"),
    path: "/gallery/2018/best-2018/three-cats",
  },
  {
    _id: "village-life",
    name: "village life",
    year: "2018",
    project: "best-2018",
    url: require("../assets/artworks/2018/village-life.jpg"),
    path: "/gallery/2018/best-2018/village-life",
  },
  {
    _id: "cat-pyramid",
    name: "cat pyramid",
    year: "2019-2020",
    project: "best-2019-2020",
    url: require("../assets/artworks/2019-2020/cat-pyramid.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/cat-pyramid",
  },
  {
    _id: "copacabana",
    name: "copacabana",
    year: "2019-2020",
    project: "best 2019-2020",
    url: require("../assets/artworks/2019-2020/copacabana.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/copacabana",
  },
  {
    _id: "dragonych",
    name: "dragonych",
    year: "2019-2020",
    project: "best-2019-2020",
    url: require("../assets/artworks/2019-2020/dragonych.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/dragonych",
  },
  {
    _id: "gold-fish",
    name: "gold fish",
    year: "2019-2020",
    project: "best-2019-2020",
    url: require("../assets/artworks/2019-2020/gold-fish.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/gold-fish",
  },
  {
    _id: "mr-fish",
    name: "mr fish",
    year: "2019-2020",
    project: "best-2019-2020",
    url: require("../assets/artworks/2019-2020/mr-fish.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/mr-fish",
  },
  {
    _id: "mrs-fish",
    name: "mrs fish",
    year: "2019-2020",
    project: "best-2019-2020",
    url: require("../assets/artworks/2019-2020/mrs-fish.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/mrs-fish",
  },
  {
    _id: "our-future",
    name: "our future",
    year: "2019-2020",
    project: "best-2019-2020",
    url: require("../assets/artworks/2019-2020/our-future.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/our-future",
  },
  {
    _id: "owlie",
    name: "owlie",
    year: "2019-2020",
    project: "best-2019-2020",
    url: require("../assets/artworks/2019-2020/owlie.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/owlie",
  },
  {
    _id: "real-prince-charming",
    name: "real prince charming",
    year: "2019-2020",
    project: "best-2019-2020",
    url: require("../assets/artworks/2019-2020/real-prince-charming.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/real-prince-charming",
  },
  {
    _id: "tokyo",
    name: "tokyo",
    year: "2019-2020",
    project: "best-2019-2020",
    url: require("../assets/artworks/2019-2020/tokyo.jpg"),
    path: "/gallery/2019-2020/best-2019-2020/tokyo",
  },
  {
    _id: "can-it-all",
    name: "can it all",
    year: "2024",
    project: "fish-and-birds",
    url: require("../assets/artworks/2024/fish-and-birds/can-it-all.jpg"),
    path: "/gallery/2024/fish-and-birds/can-it-all",
  },
  {
    _id: "pigeon",
    name: "pigeon",
    year: "2024",
    project: "fish-and-birds",
    url: require("../assets/artworks/2024/fish-and-birds/pigeon.jpg"),
    path: "/gallery/2024/fish-and-birds/pigeon",
  },
  {
    _id: "i-can-fly",
    name: "i can fly",
    year: "2024",
    project: "fish-and-birds",
    url: require("../assets/artworks/2024/fish-and-birds/i-can-fly.jpg"),
    path: "/gallery/2024/fish-and-birds/i-can-fly",
  },
  {
    _id: "quack",
    name: "quack",
    year: "2024",
    project: "fish-and-birds",
    url: require("../assets/artworks/2024/fish-and-birds/quack.jpg"),
    path: "/gallery/2017/fish-and-birds/quack",
  },
  {
    _id: "whale",
    name: "whale",
    year: "2024",
    project: "fish-and-birds",
    url: require("../assets/artworks/2024/fish-and-birds/whale.jpg"),
    path: "/gallery/2024/fish-and-birds/whale",
  },

  {
    _id: "chiquita-ii",
    name: "chiquita ii",
    year: "2024",
    project: "fish-and-birds",
    url: require("../assets/artworks/2024/fish-and-birds/chiquita-ii.jpg"),
    path: "/gallery/2017/birds/chiquita-ii",
  },

  {
    _id: "lotos",
    name: "lotos",
    year: "2024",
    project: "catyoga",
    url: require("../assets/artworks/2024/catyoga/lotos.jpg"),
    path: "/gallery/2024/catyoga/lotos",
  },
  {
    _id: "bamboo-forest",
    name: "bamboo",
    year: "2024",
    project: "catyoga",
    url: require("../assets/artworks/2024/catyoga/bamboo.jpg"),
    path: "/gallery/2024/catyoga/bamboo-forest",
  },
  {
    _id: "the-moon",
    name: "the moon",
    year: "2024",
    project: "catyoga",
    url: require("../assets/artworks/2024/catyoga/the-moon.jpg"),
    path: "/gallery/2024/catyoga/the-moon",
  },
  {
    _id: "balanced-i",
    name: "balanced i",
    year: "2024",
    project: "catyoga",
    url: require("../assets/artworks/2024/catyoga/balanced-i.jpg"),
    path: "/gallery/2024/catyoga/balanced-i",
  },
  {
    _id: "balanced-ii",
    name: "balanced ii",
    year: "2024",
    project: "catyoga",
    url: require("../assets/artworks/2024/catyoga/balanced-ii.jpg"),
    path: "/gallery/2024/catyoga/balanced-ii",
  },
  {
    _id: "bamboo-forest",
    name: "bamboo forest",
    year: "2024",
    project: "catyoga",
    url: require("../assets/artworks/2024/catyoga/bamboo-forest.jpg"),
    path: "/gallery/2024/catyoga/bamboo-forest",
  },
  {
    _id: "selfie",
    name: "selfie",
    year: "2024",
    project: "catasian-world",
    url: require("../assets/artworks/2024/catasianworld/selfie.jpg"),
    path: "/gallery/2024/catasian-world/selfie",
  },
  {
    _id: "black-ghost",
    name: "black ghost",
    year: "2024",
    project: "catasian-world",
    url: require("../assets/artworks/2024/catasianworld/black-ghost.jpg"),
    path: "/gallery/2024/catasian-world/black-ghost",
  },
  {
    _id: "ironed",
    name: "ironed",
    year: "2024",
    project: "catasian-world",
    url: require("../assets/artworks/2024/catasianworld/ironed.jpg"),
    path: "/gallery/2024/catasian-world/ironed",
  },
  {
    _id: "rainbow-flower",
    name: "rainbow flower",
    year: "2024",
    project: "catasian-world",
    url: require("../assets/artworks/2024/catasianworld/rainbow-flower.jpg"),
    path: "/gallery/2024/catasian-world/rainbow-flower",
  },
];
